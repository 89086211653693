import '@/styles/common/Admin/SearchResult/user_module.scss';

import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import FireActiveIcon from '@/assets/images/common/fire_active.png';
import FireInactiveIcon from '@/assets/images/common/fire_inactive.png';
import { AdminOfferStatus } from '@/components/common/Admin/SearchResult/AdminOfferStatus';
import { OfferStatus } from '@/components/common/Admin/SearchResult/OfferStatus';
import { PortfolioPDFDownloadButton } from '@/componentsAdmin/Portfolio/PDFDownloadButton';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { useDownloadPDF } from '@/hooks/useDownloadPDF';
import {
  ADMINApi,
  AdminDirectUserSearchModel,
  DirectApi,
  DirectUserSearchModel,
  InlineObject4,
  LastLogin,
  Offer,
  PortfolioItem,
  Question,
  UserResumeInfo,
} from '@/utils/api-client';
import { optionValueToChildren, getOptionChildrenFromValue } from '@/utils/optionValueToChildren';
import { getAge } from '@/utils/string';

type Props = {
  item: AdminDirectUserSearchModel | DirectUserSearchModel;
  type: 'direct' | 'admin';
  isDisable?: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement | HTMLElement>;
  onClickOfferPickup?: React.MouseEventHandler<HTMLButtonElement>;
};

export function UserModule(props: Props): React.ReactElement {
  type MotivationIcon = {
    isActive: boolean;
  };

  const [isOpen, setOpen] = useState<boolean>(false);
  const [motivationIcons, setMotivationIcons] = useState<Array<MotivationIcon>>();
  const [portfolioItems, setPortfolioItems] = useState<Array<PortfolioItem | null>>();
  const [validPortfolioItemsNum, setValidPortfolioItemsNum] = useState<number>(0);
  const [primaryCareer, setPrimaryCareer] = useState<string[]>([]);
  const [primaryCareerCompanyName, setPrimaryCareerCompanyName] = useState<string>('');
  const [primaryOffer, setPrimaryOffer] = useState<Offer>();
  const [finalSchoolName, setFinalSchoolName] = useState<string>();
  const [read, setRead] = useState<boolean | undefined>(props.item.is_read);
  const { downloadPDF } = useDownloadPDF();

  useEffect(() => {
    const arr = [];

    props.item.portfolio?.items?.forEach((item: PortfolioItem) => {
      arr.push(item);
    });

    setValidPortfolioItemsNum(arr.length);

    // ブランクの要素を入れる
    let length = 5 - (arr.length % 5);
    length = length === 5 ? 0 : length;

    for (let i = 0; i < length; i++) {
      arr.push(null);
    }

    setPortfolioItems(arr);
  }, [props.item]);

  useEffect(() => {
    if (props.item.offers && props.item.offers?.length) {
      setPrimaryOffer(props.item.offers[0]);
    }
  }, [props.item.offers]);


  useEffect(() => {
    const motivation = Number(props.item.mc?.mc_preferred_change_date);
    const activeNum = 6 - motivation;
    const arr = [];

    for (let i = 0; i < 5; i++) {
      arr.push({
        isActive: i + 1 < activeNum + 1,
      });
    }
    setMotivationIcons(arr);
  }, [props.item]);

  useEffect(() => {
    const arr = [];
    const found = props.item.career_history?.careers?.find((career) => !career.c_leave_flag);
    let primaryCareer;

    if (found) {
      primaryCareer = found;
    } else {
      if (props.item.career_history?.careers?.length) {
        primaryCareer = props.item.career_history?.careers[0];
      }
    }

    if (primaryCareer) {
      setPrimaryCareerCompanyName(primaryCareer.c_company_name);

      arr.push(primaryCareer.c_leave_flag ? '退職済' : '就業中');

      if (props.type === 'admin') {
        arr.push(primaryCareer.c_company_name);
      }

      if (primaryCareer.c_section) {
        arr.push(primaryCareer.c_section);
      }

      if (primaryCareer.c_post) {
        arr.push(primaryCareer.c_post);
      }

      // TODO: 雇用形態の反映
    }

    setPrimaryCareer(arr);
  }, [props.item]);

  useEffect(() => {
    if (props.item.educations && props.item.educations.length > 0) {
      const educations = [...props.item.educations];
      educations.sort((a, b) => {
        if (a.e_graduate_date && b.e_graduate_date) {
          const dateA = new Date(a.e_graduate_date);
          const dateB = new Date(b.e_graduate_date);

          if (dateA.getTime() < dateB.getTime()) {
            return 1;
          }
          if (dateA.getTime() > dateB.getTime()) {
            return -1;
          }

          return 0;
        }

        return 0;
      });

      setFinalSchoolName(educations[0].e_school_name);
    }
  }, [props.item]);

  const onClick: React.MouseEventHandler<HTMLElement | HTMLDivElement> = async (e) => {
    if (!read) {
      await props.onClick(e);
      setRead(true);
    }
  };

  const stopPropagation: React.MouseEventHandler<
    HTMLAnchorElement | HTMLButtonElement | HTMLDivElement
  > = (e) => {
    e.stopPropagation();
  };

  const gender = (value: number) => {
    return optionValueToChildren(value, FORM_OPTIONS.gender);
  };

  const income = (value: number) => {
    return optionValueToChildren(value, FORM_OPTIONS.yearly_income);
  };

  const companiesCount = (value: number) => {
    return optionValueToChildren(value, FORM_OPTIONS.companies_count);
  };

  const situation = (value: number) => {
    return optionValueToChildren(value, [
      {
        value: '2',
        children: '就業中',
      },
      {
        value: '1',
        children: '離職中',
      },
    ]);
  };

  const getExperiencedYear = (value: number) => {
    return optionValueToChildren(value, FORM_OPTIONS.experienced_year);
  };

  const getExperiencedJob = (value: number) => {
    return optionValueToChildren(value, FORM_OPTIONS.preferred_job_type);
  };

  const finalEducation = (value: number) => {
    return optionValueToChildren(value, FORM_OPTIONS.final_education);
  };

  const jobType = (value: number) => {
    return optionValueToChildren(value, FORM_OPTIONS.experienced_job);
  };

  const prefecture = (value: number) => {
    return optionValueToChildren(value, FORM_OPTIONS.prefecture);
  };

  const motivation = (value: number) => {
    const children = optionValueToChildren(value, FORM_OPTIONS.change_date);
    const after = '転職希望';

    if (value === 1) {
      return `${children}${after}`;
    } else if (value >= 2 && value <= 5) {
      return `${children}に${after}`;
    }

    return children;
  };

  const validNumber = (value: number | undefined | null): boolean => {
    return value !== undefined && value !== null && value !== 0;
  };

  const date = (value: string) => {
    const date = new Date(value);
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
  };

  const loginDate = (lastLogin: Set<LastLogin> | undefined) => {
    if (lastLogin) {
      const value = lastLogin.values().next().value;

      if (value) {
        const loginDate = value.login_date;
        return date(loginDate);
      }
    }

    return '';
  };

  const useDirect = (value: boolean) => {
    return value ? '有' : '無';
  };

  // const portfolioId = () => {
  //   const value = props.item.portfolio?.items?.values().next().value;

  //   if (value) {
  //     return value['i_id'];
  //   }

  //   return undefined;
  // };

  const responsible = useMemo(() => {
    let str = '';

    props.item.portfolio?.items?.forEach((item) => {
      item.i_pages.forEach((page) => {
        page.p_contents?.forEach((content) => {
          if (content) {
            if ((content as Question).qa_id) {
              if (typeof (content as Question).qa_id === 'string') {
                const q = content as Question;

                if (q.qa_id === 'b_2') {
                  q.qa_answer.forEach((s, index) => {
                    if (index === 0) {
                      str = `${s}`;
                    } else {
                      str = `${str} / ${s}`;
                    }
                  });
                }
              }
            }
          }
        });
      });
    });

    return str;
  }, []);

  const itemAsAdmin: AdminDirectUserSearchModel = useMemo(() => {
    return props.item as AdminDirectUserSearchModel;
  }, []);

  /**
   * ポートフォリオの件数
   */
  const pfLength = useMemo(() => {
    const item = props.item as unknown;
    return {
      all: (item as { portfolio_item_count: number }).portfolio_item_count,
      published: validPortfolioItemsNum,
    };
  }, [props.item, validPortfolioItemsNum]);

  /**
   * 通常ポートフォリオの件数表示テキスト
   */
  const portfolioCount = useMemo<string>(() => {
    if (props.type === 'admin') {
      return `${pfLength.all}作品（${pfLength.published}作品公開）`;
      // return `${props.item.portfolio_item_count_contained_invisible}作品（${pfLength.published}作品公開）`;
    } else {
      return `${pfLength.published}作品`;
    }
  }, [pfLength, props.type]);

  /**
   * 企業向けポートフォリオの件数表示テキスト
   */
  const portfolioCountAdmin = useMemo<string>(() => {
    const itemCount = props.item.direct_portfolio_item_count || 0;
    return `${itemCount}作品`;
  }, [pfLength, props.type]);

  return (
    <section
      className={cn(
        'mbx-user_module',
        { 'is-unread': !read },
        { disable: props.isDisable }
      )}
      onClick={(e) => {
        onClick(e);
        window.open(`/direct/users/${props.item.matchbox_id}`);
      }}
    >
      <div
        className={cn(`mbx-user_module__header`, {
          ['-ml-16']: !props.item.mc?.mc_preferred_change_date,
        })}
      >
        <div className="basic-info">
          {props.item.mc?.mc_preferred_change_date !== 0 &&
            props.item.mc?.mc_preferred_change_date && (
              <div className="motivation">
                <div className="motivation__icons">
                  {motivationIcons &&
                    motivationIcons.map((item: MotivationIcon, index: number) => {
                      return (
                        <img
                          className="motivation__icon"
                          src={item.isActive ? FireActiveIcon : FireInactiveIcon}
                          alt=""
                          key={index}
                        />
                      );
                    })}
                </div>
                <div className="motivation__text">
                  {motivation(Number(props.item.mc?.mc_preferred_change_date))}
                </div>
              </div>
            )}
          <p className="basic-info__items">
            {props.item.user_info?.prefecture && (
              <span className="basic-info__item">
                {prefecture(Number(props.item.user_info?.prefecture))}在住
              </span>
            )}
            {validNumber(props.item.user_info?.gender) && (
              <span className="basic-info__item">
                {gender(Number(props.item.user_info?.gender))}
              </span>
            )}
            {props.item.user_info?.birth_year && (
              <span className="basic-info__item">
                {getAge(
                  (props.item.user_info as UserResumeInfo).birth_year,
                  (props.item.user_info as UserResumeInfo).birth_month,
                  (props.item.user_info as UserResumeInfo).birth_day
                )}
                歳
              </span>
            )}
            {props.item.mc && (
              <>
                {props.item.mc?.mc_yearly_income && props.item.mc?.mc_yearly_income !== 0 && (
                  <span className="basic-info__item">
                    現年収{income(Number(props.item.mc?.mc_yearly_income))}
                  </span>
                )}
                {props.item.mc?.mc_situation && props.item.mc?.mc_situation !== 0 && (
                  <span className="basic-info__item">
                    {situation(Number(props.item.mc?.mc_situation))}
                  </span>
                )}
              </>
            )}
          </p>
        </div>
        {props.type === 'admin' && (
          <AdminOfferStatus item={props.item as AdminDirectUserSearchModel} />
        )}
        {props.type === 'direct' && (
          <OfferStatus
            offer={primaryOffer}
            onClickOfferPickup={(e) => {
              if (props.onClickOfferPickup) {
                props.onClickOfferPickup(e);
              }
              onClick(e);
            }}
          />
        )}
      </div>
      <div className="user-summary">
        {props.type === 'admin' && (
          <>
            <div
              className={cn('user-summary__name', 'mkt_mask_items', {
                'is-unread': !read,
              })}
            >
              {itemAsAdmin.user_info?.icon && (
                <img
                  className="user-summary__icon mkt_mask_items"
                  src={itemAsAdmin.user_info?.icon}
                  title="icon"
                />
              )}
              {itemAsAdmin.user_info?.name}
              {props.item.mc?.mc_experienced_job &&
                props.item.mc?.mc_experienced_year &&
                `：${getExperiencedJob(
                  Number(props.item.mc?.mc_experienced_job)
                )} 経験${getExperiencedYear(Number(props.item.mc?.mc_experienced_year))}`}
            </div>
          </>
        )}
        {props.type === 'direct' && (
          <div
            className={cn('user-summary__name', 'mkt_mask_items', {
              'is-unread': !read,
            })}
          >
            {props.item.matchbox_id}
            {props.item.mc?.mc_experienced_job &&
              props.item.mc?.mc_experienced_year &&
              `：${getExperiencedJob(
                Number(props.item.mc?.mc_experienced_job)
              )} 経験${getExperiencedYear(Number(props.item.mc?.mc_experienced_year))}`}
          </div>
        )}
      </div>
      {props.item.mc?.mc_description && (
        <div className="user-summary">
          <p className="text-12_18 text-ellipsis text-ellipsis__second__line mr-112 mkt_mask_items">
            {props.item.mc.mc_description}
          </p>
        </div>
      )}
      <div className="user-info">
        <div className="user-info__left">
          {props.item?.mc && (
            <>
              <div className="user-info-column">
                {props.item.mc?.mc_preferred_job_type && (
                  <p className="user-info-item mkt_mask_items">
                    <span className="user-info-item__header user-info-item__header--w42">
                      <span className="text">希望職種</span>
                    </span>
                    <span className="user-info-item__text">
                      {props.item.mc?.mc_preferred_job_type
                        .map((jobType: number) =>
                          getOptionChildrenFromValue('preferred_job_type', jobType)
                        )
                        .join(' / ')}
                    </span>
                  </p>
                )}
              </div>
              <div className="user-info-column">
                {validNumber(props.item.mc?.mc_preferred_yearly_income) && (
                  <p className="user-info-item mkt_mask_items">
                    <span className="user-info-item__header user-info-item__header--w42">
                      <span className="text">希望年収</span>
                    </span>
                    <span className="user-info-item__text">
                      {income(Number(props.item.mc?.mc_preferred_yearly_income))}
                    </span>
                  </p>
                )}
              </div>
              <div className="user-info-column">
                {validNumber(props.item.mc?.mc_final_education) && (
                  <p className="user-info-item mkt_mask_items">
                    <span className="user-info-item__header user-info-item__header--w42">
                      <span className="text">最終学歴</span>
                    </span>
                    <span className="user-info-item__text">
                      {finalEducation(Number(props.item.mc?.mc_final_education))}
                    </span>
                  </p>
                )}
              </div>
              <div className="user-info-column">
                {validNumber(props.item.mc?.mc_companies_count) && (
                  <p className="user-info-item mkt_mask_items">
                    <span className="user-info-item__header user-info-item__header--w42">
                      <span className="text">経験社数</span>
                    </span>
                    <span className="user-info-item__text">
                      {companiesCount(Number(props.item.mc?.mc_companies_count))}
                    </span>
                  </p>
                )}
              </div>
            </>
          )}
        </div>
        <div className="user-info__right">
          {props.type === 'admin' && (
            <p className="user-info-item">
              <span
                className={`user-info-item__header user-info-item__header--w${
                  props.type === 'admin' ? '112' : '72'
                }`}
              >
                <span className="text">企業向けポートフォリオ</span>
              </span>
              <span className="user-info-item__text">
                {/* 更新日がない場合は「未登録」 */}
                {props.item.last_update_public_portfolio ? (
                  `${date(String(props.item.last_update_public_portfolio))} 更新 `
                ) : (
                  "未登録 "
                )}
                / {portfolioCountAdmin}
              </span>
              {/* 0作品の場合はリンクを表示しない */}
              {props.item.direct_portfolio_item_count !== 0 && props.item.direct_portfolio_item_count && (
                <a
                  className="user-info-item__link"
                  href={`/${props.type}/direct/portfolio/${
                    (props.item as AdminDirectUserSearchModel).user_info?.matchbox_id
                  }`}
                  target="_blank"
                  onClick={(e) => {
                    stopPropagation(e);
                    onClick(e);
                  }}
                  rel="noreferrer"
                >
                  WEB
                  <i className="mbx-icon mbx-icon-External_link-thin"></i>
                </a>
              )}
            </p>
          )}
          <p className="user-info-item">
            <span
              className={`user-info-item__header user-info-item__header--w${
                props.type === 'admin' ? '112' : '72'
              }`}
            >
              {props.type === 'admin' ? (
                <span className="text">一般向けポートフォリオ</span>
              ) : (
                <span className="text">ポートフォリオ</span>
              )}
            </span>
            <span className="user-info-item__text">
              {/* 更新日がない場合は「未作成」or「未登録」 */}
              {props.item.last_update_portfolio
                ? `${date(String(props.item.last_update_portfolio))} 更新 `
                : props.type === 'admin'
                ? '未作成 '
                : '未登録 '}
              / {portfolioCount}
            </span>
            {/* 0作品の場合はリンクを表示しない */}
            {pfLength.published > 0 &&
              <>
                {props.type === 'admin' ? (
                  <a
                    className="user-info-item__link"
                    href={`/${props.type}/portfolio/${
                      (props.item as AdminDirectUserSearchModel).user_info?.user_id
                    }`}
                    target="_blank"
                    onClick={(e) => {
                      stopPropagation(e);
                      onClick(e);
                    }}
                    rel="noreferrer"
                  >
                    WEB
                    <i className="mbx-icon mbx-icon-External_link-thin"></i>
                  </a>
                ) : (
                  <a
                    className="user-info-item__link"
                    href={`/${props.type}/portfolio/${props.item.matchbox_id}`}
                    target="_blank"
                    onClick={(e) => {
                      stopPropagation(e);
                      onClick(e);
                    }}
                    rel="noreferrer"
                  >
                    WEB
                    <i className="mbx-icon mbx-icon-External_link-thin"></i>
                  </a>
                )}
                {props.type === 'admin' && (
                  <PortfolioPDFDownloadButton type="result" matchboxId={props.item.matchbox_id} onClick={onClick}/>
                )}
              </>
            }
          </p>
          {props.type === 'admin' && (
            <p className="user-info-item">
              <span
                className={`user-info-item__header user-info-item__header--w${
                  props.type === 'admin' ? '112' : '72'
                }`}
              >
                <span className="text">履歴書</span>
              </span>
              <span className="user-info-item__text">
                {/* 更新日がない場合は「未作成」 */}
                {props.item.last_update_resume
                  ? `${date(String(props.item.last_update_resume))} 更新`
                  : '未作成 '
                }
              </span>
              {/* 更新日がない場合はリンクを表示しない */}
              {props.item.last_update_resume && (
                <>
                  <a
                    className="user-info-item__link"
                    href={`/admin/resume/${
                      (props.item as AdminDirectUserSearchModel).user_info?.user_id
                    }`}
                    target="_blank"
                    onClick={(e) => {
                      stopPropagation(e);
                      onClick(e);
                    }}
                    rel="noreferrer"
                  >
                    WEB
                    <i className="mbx-icon mbx-icon-External_link-thin"></i>
                  </a>
                  <BaseButton
                    size="ss"
                    theme="link"
                    iconName="External_link"
                    iconLayout="right"
                    className=""
                    download={'resume.pdf'}
                    forceExternal={true}
                    href={`/api/v2/admin/resume/${props.item.matchbox_id}/download`}
                    onClick={(e) => {
                      stopPropagation(e);
                      onClick(e);
                      downloadPDF();
                    }}
                  >
                    PDF
                  </BaseButton>
                  </>
              )}
            </p>
          )}
          <p className="user-info-item">
            <span
              className={`user-info-item__header user-info-item__header--w${
                props.type === 'admin' ? '112' : '72'
              }`}
            >
              <span className="text">職務経歴書</span>
            </span>
            <span className="user-info-item__text">
              {/* 更新日がない場合は「未作成」or「未登録」 */}
              {props.item.career_history?.latest_update
                ? `${date(String(props.item.career_history.latest_update))} 更新`
                : props.type === 'admin'
                ? '未作成 '
                : '未登録 '
              }
            </span>
            {/* 更新日がない場合はリンクを表示しない */}
            {props.item.career_history?.latest_update && (
              <>
                <a
                  className="user-info-item__link"
                  href={`/${props.type}/cv/${props.item.matchbox_id}`}
                  target="_blank"
                  onClick={(e) => {
                    stopPropagation(e);
                    onClick(e);
                  }}
                  rel="noreferrer"
                >
                  WEB
                  <i className="mbx-icon mbx-icon-External_link-thin"></i>
                </a>
                {props.type === 'admin' && (
                  <BaseButton
                    size="ss"
                    theme="link"
                    iconName="External_link"
                    iconLayout="right"
                    className=""
                    download="career_history.pdf"
                    forceExternal={true}
                    href={`/api/v2/admin/career_history/${props.item.matchbox_id}/download`}
                    onClick={(e) => {
                      stopPropagation(e);
                      onClick(e);
                      downloadPDF();
                    }}
                  >
                    PDF
                  </BaseButton>
                )}
              </>
            )}
          </p>
        </div>

        {props.type === 'direct' && (
          <>
            {portfolioItems && portfolioItems.length > 0 ? (
              <div className="user-info__image mkt_mask_items">
                <img src={portfolioItems[0]?.i_image.f_thumbnail} alt="" />
              </div>
            ) : (
              <>
                <div className="user-info__image user-info__image--blank" />
              </>
            )}
          </>
        )}

        {props.type === 'admin' && (
          <>
            {props.item.portfolio?.direct_items && props.item.portfolio?.direct_items.length > 0 ? (
              <div className="user-info__image mkt_mask_items">
                <img src={props.item.portfolio?.direct_items[0].i_image.f_thumbnail} alt="" />
              </div>
            ) : (
              <>
                <div className="user-info__image user-info__image--blank" />
              </>
            )}
          </>
        )}
      </div>
      {isOpen && (
        <div className="portfolio">
          {props.type === 'direct' &&
            portfolioItems &&
            validPortfolioItemsNum > 1 &&
            portfolioItems.map((item: PortfolioItem | null, index: number) => (
              <>
                {item ? (
                  <div className="portfolio-item mkt_mask_items" key={index}>
                    <img className="portfolio-item__image" src={item.i_image.f_thumbnail} alt="" />
                  </div>
                ) : (
                  <div className="portfolio-item portfolio-item--blank" key={index}></div>
                )}
              </>
            ))}

          {props.type === 'admin' &&
            props.item.portfolio?.direct_items &&
            props.item.portfolio?.direct_items.length > 0 &&
            props.item.portfolio?.direct_items.map((item: PortfolioItem | null, index: number) => (
              <>
                {item ? (
                  <div className="portfolio-item mkt_mask_items" key={index}>
                    <img className="portfolio-item__image" src={item.i_image.f_thumbnail} alt="" />
                  </div>
                ) : (
                  <div className="portfolio-item portfolio-item--blank" key={index}></div>
                )}
              </>
            ))}
        </div>
      )}
      <p className="account-info">
        {props.type === 'admin' && (
          <>
            <span className="account-info__item">MB ID：{props.item.matchbox_id}</span>
            <span className="account-info__item">連携 ID：{props.item.user_entry_id}</span>
            <span className="account-info__item">
              オファー機能登録：{useDirect(Boolean(itemAsAdmin.user_info?.use_direct))}
            </span>
          </>
        )}
        <span className="account-info__item">
          アカウント開設日：{date(String(props.item.registration_date))}
        </span>
        <span className="account-info__item">
          最終ログイン日：{loginDate(props.item.last_login)}
        </span>
      </p>
      {props.type === 'direct' &&
        portfolioItems &&
        validPortfolioItemsNum > 1 &&
        (!isOpen ? (
          <button
            className="open-close-button"
            onClick={(e) => {
              setOpen(true);
              stopPropagation(e);
            }}
          >
            <i className="open-close-button__icon mbx-icon mbx-icon-Arrow_Down"></i>
            <span className="open-close-button__text">もっと作品を見る</span>
          </button>
        ) : (
          <button
            className="open-close-button"
            onClick={(e) => {
              setOpen(false);
              stopPropagation(e);
            }}
          >
            <i className="open-close-button__icon mbx-icon mbx-icon-Arrow_Up"></i>
            <span className="open-close-button__text">閉じる</span>
          </button>
        ))}

      {props.type === 'admin' &&
        props.item.portfolio?.direct_items &&
        props.item.portfolio?.direct_items.length > 1 &&
        (!isOpen ? (
          <button
            className="open-close-button"
            onClick={(e) => {
              setOpen(true);
              stopPropagation(e);
            }}
          >
            <i className="open-close-button__icon mbx-icon mbx-icon-Arrow_Down"></i>
            <span className="open-close-button__text">もっと作品を見る</span>
          </button>
        ) : (
          <button
            className="open-close-button"
            onClick={(e) => {
              setOpen(false);
              stopPropagation(e);
            }}
          >
            <i className="open-close-button__icon mbx-icon mbx-icon-Arrow_Up"></i>
            <span className="open-close-button__text">閉じる</span>
          </button>
        ))}
      <i className="mbx-user_module__arrow mbx-icon mbx-icon-Modal_Arrow_Right"></i>
    </section>
  );
}
