import dayjs from 'dayjs';

import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';
import {
  AdminDirectCompanySearchConditionModel,
  AdminSearchConditionModel,
  CommonSCMcExperiencedJobObjectModel,
  DirectSearchConditionModel,
} from '@/utils/api-client';
import {
  getDirectOptionChildrenFromValue,
  getOptionChildrenFromValue,
} from '@/utils/optionValueToChildren';
import { noDataText } from '@/utils/string';

type TOption = {
  value: string;
  children: string;
};

const optionValueToChildren = (
  value: number | string,
  options: Array<{ value: string; children: string }>
) => {
  const found = options.find((item) => {
    if (value === null) return '';
    if (value === undefined) return '';
    return item.value === value.toString();
  });

  return found?.children;
};

/**
 * 管理者画面 : 企業検索条件をテキストに変換する
 */
export const searchConditionsToTextForADCSCM = (
  conditions: AdminDirectCompanySearchConditionModel
): string => {
  return Object.keys(conditions)
    .map((key: string): string => {
      const value = conditions[key as keyof AdminDirectCompanySearchConditionModel];

      const genDateRange = (data: Array<string>): string => {
        return `${dayjs(data[0]).format('YYYY年MM月DD日')}-${dayjs(data[1]).format(
          'YYYY年MM月DD日'
        )}`;
      };

      switch (key as keyof AdminDirectCompanySearchConditionModel) {
        case 'keyword': //?: Array<string>;
          if (typeof value === 'string') return value;
          return (value as Array<string>).join(' ');
        case 'description': //?: Array<string>;
          if (typeof value === 'string') return value;
          return (value as Array<string>).join(' ');

        case 'directompany_id': //?: number;
          if (!value) return '';
          return `企業 ID: ${value as number}`;
        case 'block_company_id': //?: string;
          if (!value) return '';
          return `国税庁番号: ${value as string}`;
        case 'direct_company_user_id': //?: string;
          if (!value) return '';
          return `企業担当者ID: ${value as string}`;

        case 'last_login': //?: Array<string>;
          if (typeof value === 'object' && value.length === 0) return '';
          if (!value) return '';
          return `最終ログイン日 ${genDateRange(value as Array<string>)}`;
        case 'registration_date': //: Array<string>;
          if (typeof value === 'object' && value.length === 0) return '';
          if (!value) return '';
          return `アカウント開設日 ${genDateRange(value as Array<string>)}`;

        case 'business_type': //?: Array<number>;
          if (typeof value === 'object')
            return (value as Array<number>)
              .map((val) => getOptionChildrenFromValue('business_type', val))
              .join('/');
          return getOptionChildrenFromValue('business_type', value as number);
        case 'prefecture': //?: number;
          return getOptionChildrenFromValue('prefecture', value as number);
        case 'employee_amount': //?: number;
          return getOptionChildrenFromValue('employee_amount', value as number);
        case 'mw_user': //?: string;
          return getDirectOptionChildrenFromValue('mwStatus', value as number);
        default:
          // case "name"://?: string;
          // case "kana"://?: string;
          // case "address"://?: string;
          // case "direct_company_user_email"://?: string;
          // case "direct_company_user_name"://?: string;
          if (key.includes('-from')) return '';
          if (key.includes('-to')) return '';
          return value as string;
      }
    })
    .filter((value) => value !== '' && value !== undefined && value.length > 0)
    .join('、');
};

/**
 * 検索条件をテキストに変換する
 */
export const searchConditionsToText = (
  conditions: AdminSearchConditionModel | DirectSearchConditionModel,
  type?: 'admin' | 'direct'
): string => {
  const {
    keyword,
    offer_status,
    rate,
    offer_update_at,
    matchbox_id,
    registration_date,
    last_login,
    prefecture,
    gender,
    age,
    mc_experienced_job,
    mc_preferred_change_date,
    mc_situation,
    career_history_current_companies,
    mc_yearly_income,
    career_history_current_section,
    career_history_current_post,
    portfolio_item_count,
    last_update_portfolio,
    last_update_career_history,
    career_history_amount,
    career_history_companies,
    career_history_section,
    career_history_post,
    mc_maximum_management_count,
    qa_answer,
    career_history_experiences,
    career_history_tools,
    q_title,
    q_toeic_score,
    q_toefl_score,
    mc_english_skills,
    e_school_name,
    final_education,
    mc_preferred_job_type,
    mc_preferred_business_type,
    mc_preferred_prefecture,
    mc_preferred_yearly_income,
    mc_preferred_employment_status,
    mc_conditions,
  } = conditions;

  const {
    user_admin_tags,
    mw_status,
    offer_amount,
    offer_pickup_amount,
    user_entry_id,
    portfolio_state,
    portfolio_url,
    connected_to_mbdirect,
    name,
    furigana,
    email,
    tel,
    address,
    birth_date,
    last_update_use_direct,
    last_update_resume,
    published_portfolio_item_count,
    direct_portfolio_item_count,
    user_tags,
  } = conditions as AdminSearchConditionModel;

  let str = '';

  const append = (item: string | number | undefined) => {
    if (!item) {
      return;
    }

    if (str.length === 0) {
      str = `${item}`;
    } else {
      str = `${str} / ${item}`;
    }
  };

  const arrayToString = (arr: number[] | string[]) => {
    let str = '';

    arr.forEach((item: number | string, index: number) => {
      if (index === 0) {
        str = `${item}`;
      } else {
        str = `${str}、${item}`;
      }
    });

    return str;
  };

  const rangeToString = (arr: (number | string)[]): string => {
    if (arr.length === 1) {
      return `${arr[0]}`;
    }

    return `${noDataText(arr[0])}〜${noDataText(arr[1])}`;
  };

  const dateRangeToString = (arr: string[]): string => {
    if (arr.length === 1) {
      return `${arr[0]}`;
    }

    return `${noDataText(arr[0])}〜${noDataText(arr[1])}`;
  };

  const rangeToNumber = (
    arr: number[],
    options: Array<{ value: string; children: string }>
  ): string => {
    if (arr.length === 1) {
      return `${optionValueToChildren(Number(arr[0]), options)}`;
    }

    return `${noDataText(optionValueToChildren(Number(arr[0]), options))}〜${noDataText(
      optionValueToChildren(Number(arr[1]), options)
    )}`;
  };

  const multipleOptionValuesToString = (
    arr: Array<number | string>,
    options: Array<TOption>
  ): string => {
    let str = '';

    arr.forEach((item: number | string, index: number) => {
      const children = optionValueToChildren(item, options);
      const validChildren = children ? children : '';

      if (index === 0) {
        str = validChildren;
      } else {
        str = `${str}、${validChildren}`;
      }
    });

    return str;
  };

  const lastUpdateNumberOrArrayOfString = (value: number | Array<string>) => {
    if (Array.isArray(value) && value.length > 0) {
      return dateRangeToString(value);
    } else if (typeof Number(value) === 'number') {
      return optionValueToChildren(Number(value), DIRECT_FORM_OPTIONS.last_update);
    }
  };

  const isNotEmptyNumberOrArrayOfString = (value: number | Array<string> | undefined): boolean => {
    if (Array.isArray(value)) {
      return value.length > 0;
    } else if (typeof Number(value) === 'number') {
      return !isNaN(Number(value));
    }

    return false;
  };

  if (keyword) append(arrayToString(keyword));

  if (offer_status) {
    if (type && type === 'admin') {
      append(multipleOptionValuesToString(offer_status, DIRECT_FORM_OPTIONS.offer_status));
    } else {
      append(multipleOptionValuesToString(offer_status, DIRECT_FORM_OPTIONS.directSearchStatus));
    }
  }
  if (rate && rate.length > 0)
    append(`マッチ度 ${multipleOptionValuesToString(rate, DIRECT_FORM_OPTIONS.offer_mw_status)}`);

  if (offer_update_at && offer_update_at.length > 0)
    append(`選考ステータス最終更新日 ${dateRangeToString(offer_update_at as string[])}`);

  if (user_admin_tags && user_admin_tags.length > 0)
    append(`管理タグ ${arrayToString(user_admin_tags)}`);

  if (mw_status)
    append(`オファーの対応状況 ${optionValueToChildren(mw_status, DIRECT_FORM_OPTIONS.mwStatus)}`);

  if (offer_amount && offer_amount.length > 0) append(`オファー数 ${rangeToString(offer_amount)}`);

  if (offer_pickup_amount && offer_pickup_amount.length > 0)
    append(`ピックアップ数 ${rangeToString(offer_pickup_amount)}`);

  if (matchbox_id) append(`MATCHBOX ID ${matchbox_id}`);
  if (user_entry_id) append(`広告効果測定用ID ${user_entry_id}`);

  if (registration_date && isNotEmptyNumberOrArrayOfString(registration_date))
    append(`アカウント開設日 ${lastUpdateNumberOrArrayOfString(registration_date)}`);

  if (last_login && isNotEmptyNumberOrArrayOfString(last_login))
    append(`最終ログイン日 ${lastUpdateNumberOrArrayOfString(last_login)}`);

  if (last_update_use_direct && isNotEmptyNumberOrArrayOfString(last_update_use_direct))
    append(`最終オファー登録日 ${lastUpdateNumberOrArrayOfString(last_update_use_direct)}`);

  if (portfolio_url) append(`ポートフォリオURL ${portfolio_url}`);

  if (
    connected_to_mbdirect !== undefined &&
    (String(connected_to_mbdirect) === '0' || String(connected_to_mbdirect) === '1')
  )
    append(
      `オファー機能 ${optionValueToChildren(
        connected_to_mbdirect,
        DIRECT_FORM_OPTIONS.genericRegistrationStatus
      )}`
    );

  if (name) append(`氏名 ${name}`);
  if (furigana) append(`ふりがな ${furigana}`);
  if (email) append(`メールアドレス ${email}`);
  if (tel) append(`電話番号 ${tel}`);
  if (prefecture) append(optionValueToChildren(prefecture, FORM_OPTIONS.prefecture));
  if (address) append(`市町村区 ${address}`);
  if (gender || gender === 0) append(`性別 ${optionValueToChildren(gender, FORM_OPTIONS.gender)}`);
  if (age && age.length > 0) append(`${rangeToString(age)}歳`);
  if (birth_date && birth_date.length > 0) append(`生年月日 ${rangeToString(birth_date)}`);
  if (mc_experienced_job && mc_experienced_job.length > 0) {
    let str = '';
    mc_experienced_job.forEach((item: CommonSCMcExperiencedJobObjectModel, index) => {
      if (item.job && item.job_year && item.job_year.length) {
        const job = optionValueToChildren(item.job, FORM_OPTIONS.preferred_job_type);
        const year = multipleOptionValuesToString(item.job_year, FORM_OPTIONS.experienced_year);
        if (index === 0 || !str) {
          str = `${job} ${year}`;
        } else {
          str = `${str}、${job} ${year}`;
        }
      }
    });

    append(str);
  }
  if (mc_preferred_change_date && mc_preferred_change_date.length)
    append(
      `転職希望時期 ${multipleOptionValuesToString(
        mc_preferred_change_date,
        FORM_OPTIONS.change_date
      )}`
    );
  if (mc_situation) append(optionValueToChildren(mc_situation, FORM_OPTIONS.situation));
  if (career_history_current_companies) append(`在籍企業 ${career_history_current_companies}`);
  if (mc_yearly_income && mc_yearly_income.length)
    append(
      `現在の年収 ${multipleOptionValuesToString(mc_yearly_income, FORM_OPTIONS.yearly_income)}`
    );

  if (career_history_current_section) append(`現在の在籍部署名 ${career_history_current_section}`);
  if (career_history_current_post) append(`現在の役職名 ${career_history_current_post}`);
  if (portfolio_item_count !== undefined && portfolio_item_count.length > 0) {
    const _portfolio_item_count = portfolio_item_count.map((itemCount) => {
      return itemCount >= 0 && itemCount !== null ? String(itemCount) : itemCount;
    });
    append(`ポートフォリオ作品数 ${rangeToString(_portfolio_item_count)}`);
  }
  if (published_portfolio_item_count !== undefined && published_portfolio_item_count.length > 0) {
    const _published_portfolio_item_count = published_portfolio_item_count.map((itemCount) => {
      return itemCount >= 0 && itemCount !== null ? String(itemCount) : itemCount;
    });
    append(`ポートフォリオ公開作品数 ${rangeToString(_published_portfolio_item_count)}`);
  }

  if (direct_portfolio_item_count !== undefined && direct_portfolio_item_count.length > 0) {
    const _direct_portfolio_item_count = direct_portfolio_item_count.map((itemCount) => {
      return itemCount >= 0 && itemCount !== null ? String(itemCount) : itemCount;
    });
    append(`企業向けポートフォリオ公開作品数 ${rangeToString(_direct_portfolio_item_count)}`);
  }

  if (portfolio_state)
    append(
      `ポートフォリオ公開状態 ${optionValueToChildren(
        portfolio_state,
        FORM_OPTIONS.portfolio_status
      )}`
    );

  if (last_update_portfolio && isNotEmptyNumberOrArrayOfString(last_update_portfolio))
    append(`ポートフォリオ更新日 ${lastUpdateNumberOrArrayOfString(last_update_portfolio)}`);

  if (last_update_career_history && isNotEmptyNumberOrArrayOfString(last_update_career_history))
    append(`職務経歴書更新日 ${lastUpdateNumberOrArrayOfString(last_update_career_history)}`);

  if (last_update_resume && isNotEmptyNumberOrArrayOfString(last_update_resume))
    append(`履歴書更新日 ${lastUpdateNumberOrArrayOfString(last_update_resume)}`);

  if (career_history_amount && career_history_amount.length > 0)
    append(`経験社数 ${rangeToNumber(career_history_amount, FORM_OPTIONS.companies_count)}`);

  if (career_history_companies && career_history_companies.length > 0)
    append(`これまでの在籍企業 ${arrayToString(career_history_companies)}`);

  if (career_history_section && career_history_section.length > 0)
    append(`これまでの部署名 ${arrayToString(career_history_section)}`);

  if (career_history_post && career_history_post.length > 0)
    append(`これまでの役職名 ${arrayToString(career_history_post)}`);

  if (mc_maximum_management_count && mc_maximum_management_count.length)
    append(
      `最大マネジメント人数 ${multipleOptionValuesToString(
        mc_maximum_management_count,
        FORM_OPTIONS.maximum_management_count
      )}`
    );

  if (qa_answer && qa_answer.length > 0) append(`担当内容 ${arrayToString(qa_answer)}`);

  if (career_history_experiences && career_history_experiences.length > 0)
    append(`経験 ${arrayToString(career_history_experiences)}`);

  if (career_history_tools && career_history_tools.length > 0)
    append(`使用ツール・言語 ${arrayToString(career_history_tools)}`);

  if (q_title && q_title.length > 0) append(`資格・免許 ${arrayToString(q_title)}`);
  if (q_toeic_score) append(`TOEIC ${q_toeic_score}点以上`);
  if (q_toefl_score) append(`TOEFL ${q_toefl_score}点以上`);

  if (mc_english_skills)
    append(`${optionValueToChildren(mc_english_skills, FORM_OPTIONS.english_skills)}`);

  if (e_school_name && e_school_name.length > 0) append(arrayToString(e_school_name));
  if (final_education && final_education.length)
    append(
      `最終学歴 ${multipleOptionValuesToString(final_education, FORM_OPTIONS.final_education)}`
    );
  if (mc_preferred_job_type && mc_preferred_job_type.length)
    append(
      `希望職種 ${multipleOptionValuesToString(
        mc_preferred_job_type,
        FORM_OPTIONS.experienced_job
      )}`
    );
  if (mc_preferred_business_type && mc_preferred_business_type.length)
    append(
      `希望業種 ${multipleOptionValuesToString(
        mc_preferred_business_type,
        FORM_OPTIONS.business_type
      )}`
    );
  if (mc_preferred_prefecture && mc_preferred_prefecture.length)
    append(
      `希望勤務地 ${multipleOptionValuesToString(mc_preferred_prefecture, FORM_OPTIONS.prefecture)}`
    );
  if (mc_preferred_yearly_income && mc_preferred_yearly_income.length)
    append(
      `希望年収 ${multipleOptionValuesToString(
        mc_preferred_yearly_income,
        FORM_OPTIONS.yearly_income
      )}`
    );
  if (mc_preferred_employment_status && mc_preferred_employment_status.length)
    append(
      `希望の雇用形態・働き方 ${multipleOptionValuesToString(
        mc_preferred_employment_status,
        FORM_OPTIONS.employment_status
      )}`
    );
  if (mc_conditions && mc_conditions.length)
    append(
      `こだわりの条件 ${multipleOptionValuesToString(mc_conditions, FORM_OPTIONS.conditions)}`
    );

  if (user_tags && user_tags.length > 0) append(`タグ ${arrayToString(user_tags)}`);

  return str;
};

/**
 * ユーザー検索で並び替え用のvalueからsort_orderとsort_byの値を返却
 */
export const getSortData = (value: string): { sort_by: string; sort_order: string } => {
  const sortValues = value.split('-');
  return {
    sort_by: sortValues[0],
    sort_order: sortValues[1],
  };
};
