import { AxiosError } from 'axios';
import React, { useContext, useEffect, useState, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NewsList from '@/assets/json/news.json';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import Modal from '@/components/common/Modal';
import { MypageAssetsBox } from '@/components/common/Mypage/Assets/Box';
import { MypageDirectbox } from '@/components/common/Mypage/Directbox';
import { MypageNews } from '@/components/common/Mypage/News';
import { MypageUploadfileBox } from '@/components/common/Mypage/Uploadfile/Box';
import { MypageUploadfileList } from '@/components/common/Mypage/Uploadfile/List';
import { Onboarding } from '@/components/common/Onboarding/Onboarding';
import { Wrapper } from '@/components/common/Page';
import { notificationError, notificationSuccess, toggleLoading, userInfo } from '@/redux/index';
import { State } from '@/redux/state';
import {
  MatchboxUserResponse,
  MyNavi,
  MyNaviApi,
  Offer,
  ResponseError,
  UserApi,
} from '@/utils/api-client/api';

type TMypageUploadfileListProps = React.ComponentProps<typeof MypageUploadfileList>;

/**
 * # マイページ /mypage
 * UI PC https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Finalesign-and-Design-system?node-id=735%3A7129
 * UI SP https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=735%3A4113
 * API
 */
export const MypageTop: VFC = () => {
  // ====================================================================================================
  //
  // HOOKS
  //
  // ====================================================================================================
  const { push } = useContext(DataLayerContext);

  // ====================================================================================================
  //
  // REDUX
  //
  // ====================================================================================================
  const user = useSelector((state: State) => state.user);
  const dispatch = useDispatch();

  // ====================================================================================================
  //
  // seuState
  //
  // ====================================================================================================
  const [userData, setUser] = useState<MatchboxUserResponse>();
  const [myNavi, setMyNavi] = useState<MyNavi>();
  const [offer, setOffer] = useState<Offer[]>();
  const [offerLength, setOfferLength] = useState<number>(0);
  const [useDirect, setUseDirect] = useState<boolean>(false);
  const [resume, setResume] = useState<TMypageUploadfileListProps | null>(null);
  const [career, setCareer] = useState<TMypageUploadfileListProps | null>(null);
  const [descriptionOnly, setDescriptionOnly] = useState<boolean>(false);
  const [onboarding, setOnboarding] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);
  const [modalIcon, setModalIcon] = useState<boolean>(false);

  // ====================================================================================================
  //
  // METHOD
  //
  // ====================================================================================================
  const uploadDocument = async (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    if (!e.target.files?.length) return;
    const imageFile = e.target.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(imageFile);
    const formData = new FormData();
    formData.append('files', imageFile);
    sendDocument(formData.get('files') as string, name);
    e.target.value = '';
  };

  const updateResumeCareer = () => {
    if (!userData?.resume) {
      setResume(null);
    } else {
      setResume({
        date: userData?.resume?.date,
        name: userData?.resume?.name,
        size: userData?.resume?.size,
        fileDelete: () => deleteDocument(userData?.resume?.f_id),
        pdfDl: userData?.resume?.url,
      });
    }
    if (!userData?.career_history) {
      setCareer(null);
    } else {
      setCareer({
        date: userData?.career_history?.date,
        name: userData?.career_history?.name,
        size: userData?.career_history?.size,
        fileDelete: () => deleteDocument(userData?.career_history?.f_id),
        pdfDl: userData?.career_history?.url,
      });
    }
  };

  // ====================================================================================================
  //
  // API
  //
  // ====================================================================================================
  const deleteDocument = async (id: string | undefined) => {
    if (id) {
      dispatch(toggleLoading(true));
      new UserApi()
        .deleteDocuments(id)
        .then(() => {
          dispatch(notificationSuccess('書類の削除が完了しました。'));
          getUser();
        })
        .catch((error: AxiosError<ResponseError>) => {
          if (error.response) dispatch(notificationError(error.response.data.error_message));
        })
        .finally(() => {
          dispatch(toggleLoading(false));
        });
    }
  };

  const sendDocument = async (files: string, type: string) => {
    dispatch(toggleLoading(true));
    new UserApi()
      .postDocuments(files, type)
      .then(() => {
        push({
          event: 'fileUpload',
          actionType: 'file_upload',
          actionName: type === 'resume' ? '履歴書' : '職務経歴書',
        });
        dispatch(notificationSuccess('書類のアップロードが完了しました。'));
        getUser();
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  const getUser = () => {
    (async () => {
      const user = await new UserApi().getUser();
      if (user.status === 200) {
        setUser(user.data);
      }
    })();
  };

  const getMynaviCreator = () => {
    (async () => {
      const res = await new MyNaviApi().getMynaviCreator();
      const mynaviCreator = res.data.mynavi_creator;

      // 以下の項目が未選択の場合、オンボーディングを表示させる
      const requiredItem: (keyof MyNavi)[] = [
        'mc_experienced_job',
        'mc_experienced_year',
        'mc_final_education',
        'mc_companies_count',
        'mc_yearly_income',
        'mc_preferred_job_type',
        'mc_preferred_yearly_income',
        'mc_preferred_change_date',
        'mc_preferred_employment_status',
        'mc_description',
      ];
      const unselectedItem = requiredItem.filter((item) => !mynaviCreator[item]);
      if (unselectedItem.length) {
        if (unselectedItem.length === 1 && unselectedItem.includes('mc_description'))
          setDescriptionOnly(true);
        setMyNavi(mynaviCreator);
        setOnboarding(true);
      }
    })();
  };

  const getOffer = () => {
    (async () => {
      const res = await new UserApi().getOffers('9999', '0', '', '');
      if (res.status === 200) {
        setOffer(res.data.offers);
      }
    })();
  };

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    if (userData?.user_info) {
      dispatch(userInfo(userData?.user_info));
      if (!userData.user_info.v2_agreement) setOnboarding(true);
    }
  }, [userData?.user_info?.matchbox_id]);

  useEffect(() => {
    updateResumeCareer();
    if (userData?.user_info?.use_direct) {
      setUseDirect(true);
      getOffer();
    }
  }, [userData]);

  useEffect(() => {
    if (!userData?.last_login) return;
    if (pending) return;
    setPending(true);
  }, [userData?.last_login]);

  useEffect(() => {
    if (offer) {
      setOfferLength(offer?.filter((o) => !o.is_read).length);
    }
  }, [offer]);

  useEffect(() => {
    try {
      dispatch(toggleLoading(true));
      getUser();
      getMynaviCreator();
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  }, []);

  // Modalの中にModalがあるとautoになるので強制的にhiddenにする
  useEffect(() => {
    if (onboarding) document.body.style.overflow = 'hidden';
  }, [document.body.style.overflow]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <Wrapper
      header={
        <>
          <MypageNews list={NewsList} />
          <MypageDirectbox offers={offerLength} registration={useDirect} />
        </>
      }
    >
      <>
        <div className={'md:flex lg:flex flex-wrap justify-between'}>
          <MypageAssetsBox
            editHref="/mypage/portfolio/edit"
            type="portfolio"
            className="mt-48 flex-shrink-0 sm:mx-auto"
          />
          <MypageAssetsBox
            editHref="/mypage/resume/edit"
            downloadFileName="resume.pdf"
            pdfDl={`/api/v2/resume/download`}
            type="resume"
            className="mt-48 flex-shrink-0 sm:mx-auto"
          />
          <MypageAssetsBox
            editHref="/mypage/cv/edit"
            downloadFileName="career.pdf"
            pdfDl={`/api/v2/career_history/${user?.user_id}/download`}
            type="career"
            className="mt-48 md:flex-grow lg:flex-shrink-0 sm:mx-auto"
          />
        </div>
        <MypageUploadfileBox
          resumeUpload={(event) => uploadDocument(event, 'resume')}
          careerUpload={(event) => uploadDocument(event, 'career')}
          className="mt-48 sm:mb-212"
          resume={resume}
          career={career}
        />
        {user && (
          <Modal
            isOpen={onboarding}
            isClose={() => setOnboarding(false)}
            className="rounded-modalContainer"
            contentClass={modalIcon ? '' : 'content-center'}
            iconClass={modalIcon ? '' : 'btn-hidden'}
          >
            <Onboarding
              setModalIcon={setModalIcon}
              terms={user.v2_agreement}
              descriptionOnly={descriptionOnly}
              myNavi={myNavi}
            />
          </Modal>
        )}
      </>
    </Wrapper>
  );
};
